import React from "react"
import PropTypes from "prop-types"
import HELPERS from "utils/helpers"
import "./pageHeader.scss"

const PageHeader = ({ backgroundColor, description, headline }) => (
  <div
    className="text-center"
    id="pageHeader"
    style={{ backgroundColor: HELPERS.getColor(backgroundColor) }}
  >
    <div className="wrapper">
      {headline && <h1 className="headline">{headline}</h1>}
      {description && <p className="subHeadline">{description?.description}</p>}
    </div>
  </div>
)

export const PageHeaderProps = {
  backgroundColor: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  headline: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
  }),
}

PageHeader.propTypes = PageHeaderProps

export default PageHeader
