import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import PATHS from "static/constants/paths"
import RichText from "components/elements/richText"
import PageHeader from "components/sections/pageHeader"
import Link from "components/elements/link"
import CategoryCard from "components/blocks/categoryCard"
import "./learnDetails.scss"

const GetCareNotesSlugPrefix = () => {
  const query = useStaticQuery(
    graphql`
      query CareNotesSlugPrefix {
        template: contentfulWebPageTemplate(model: { eq: "Care Note" }) {
          slugPrefix
        }
      }
    `
  )
  return query.template.slugPrefix
}

const LearnDetails = ({ careNote }) => {
  const renderMoreResources = resources =>
    resources
      .map(resource => ({
        ...resource,
        slug: `/${GetCareNotesSlugPrefix()}/${resource.slug}`,
      }))
      .map((resource, index) => (
        <div className="col-md-4 col-12 my-3" key={index}>
          <CategoryCard {...resource} categoryKey="learn" />
        </div>
      ))

  return (
    <>
      <div className="bg-offWhite care-notes-wrapper" id="content">
        <div className="pageHeader-wrapper">
          <PageHeader
            backgroundColor={careNote.category?.theme}
            headline={careNote.title}
            link={{
              label: careNote.category?.name,
              route: `/${PATHS.CARE_NOTES}?category=${careNote.category?.id}`,
            }}
          />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center rich-text-learn">
              <RichText richText={careNote.details} />
            </div>
          </div>
          <div className="author">
            <div className="row justify-content-center">
              <div className="col-9 m-auto">
                <div className="row">
                  {careNote.authors?.map((author, i) => (
                    <div
                      className="col-md-6 col-12 d-flex align-items-center"
                      key={i}
                    >
                      {author?.picture && (
                        <div
                          className="avatar"
                          style={{ height: "89px", width: "89px" }}
                        >
                          {
                            <img
                              alt={author.picture.title}
                              src={author.picture.fluid?.src}
                            />
                          }
                        </div>
                      )}
                      <div className="author-name">
                        <span>Author:</span>{" "}
                        {author?.department === "Leadership" ? (
                          <Link to={`/${PATHS.BIOS}/${author?.slug}`}>
                            {author?.name}
                          </Link>
                        ) : (
                          author?.name
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {careNote.resources && (
        <div className="more-resources">
          <div className="container">
            <div className="headline">More Resources</div>
            <div className="container">
              <div className="row justify-content-center">
                {renderMoreResources(careNote.resources)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

LearnDetails.props = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      department: PropTypes.string,
      name: PropTypes.string,
      picture: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
        }),
        title: PropTypes.string,
      }),
      slug: PropTypes.string,
    })
  ),
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    theme: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  details: PropTypes.shape({
    json: PropTypes.object,
  }),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        theme: PropTypes.string,
      }),
      id: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

LearnDetails.propTypes = {
  careNote: PropTypes.shape(LearnDetails.props),
}

export default LearnDetails
