import React from "react"
import PropTypes from "prop-types"
import SECTIONS from "utils/sectionConstants"
import { CallToActionProps } from "components/blocks/callToAction"
import LearnDetails from "views/learnDetails"

const LearnTemplate = ({ careNote, sections }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case SECTIONS.TemplateDetailsSection:
        return <LearnDetails careNote={careNote} key={i} />

      default:
        return null
    }
  })

const SectionHeroPhotoProps = {
  __typename: PropTypes.string,
  action: PropTypes.shape(CallToActionProps),
  backgroundImage: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  headlineColor: PropTypes.string,
  headlineHero: PropTypes.shape({
    headlineHero: PropTypes.string,
  }),
  style: PropTypes.string,
}

const TemplateSectionProps = {
  __typename: PropTypes.string,
  additionalSettings: PropTypes.shape({
    enableDirectionsCTA: PropTypes.bool,
  }),
  title: PropTypes.string,
}

export const LearnTemplatDetailsProps = LearnDetails.props

LearnTemplate.props = {
  model: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(SectionHeroPhotoProps),
      PropTypes.shape(TemplateSectionProps),
    ])
  ),
  slugPrefix: PropTypes.string,
  title: PropTypes.string,
}

LearnTemplate.propTypes = {
  ...LearnTemplate.props,
  careNote: PropTypes.shape(LearnDetails.props),
}

export default LearnTemplate
