import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Template, { LearnTemplatDetailsProps } from "./template"
import LOGO from "images/cortica-logo.svg"

const siteUrl = process.env.GATSBY_SITE_URL || "https://corticacare.com"

const TemplateContainer = ({
  data: { careNote, footer, header, template },
}) => {
  // care-notes article
  const schema = {
    "@context": "https://schema.org",
    "@type": "Article",
    author: {
      "@type": "Organization",
      name: "Cortica Care",
    },
    datePublished: careNote.createdAt,
    description: careNote.meta?.description,
    headline: careNote.meta?.title,
    image: careNote.meta?.image?.file?.url,
    mainEntityOfPage: {
      "@id": `${siteUrl}/care-notes/${careNote.slug}`,
      "@type": "WebPage",
    },
    publisher: {
      "@type": "Organization",
      logo: {
        "@type": "ImageObject",
        url: LOGO,
      },
      name: "Cortica Care",
    },
  }

  return (
    <>
      <Seo
        {...careNote.meta}
        schemaMarkup={schema}
        slug={`${template.slugPrefix}/${careNote.slug}`}
      />
      <Header {...header} />
      <Template {...template} careNote={careNote} />
      <Footer {...footer} />
    </>
  )
}

TemplateContainer.props = {
  ...LearnTemplatDetailsProps,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    careNote: PropTypes.shape(TemplateContainer.props),
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    template: PropTypes.shape(Template.props),
  }),
}

export default TemplateContainer

export const query = graphql`
  query CareNote($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header" }) {
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer" }) {
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    careNote: contentfulCareNote(slug: { eq: $slug }) {
      createdAt
      slug
      title
      authors {
        department
        name
        slug
        picture {
          title
          fluid(maxHeight: 200, maxWidth: 200, quality: 90) {
            src
          }
        }
      }
      category {
        id
        name
        theme
      }
      details {
        json
      }
      resources {
        id
        slug
        title
        category {
          id
          name
          theme
        }
      }
      meta: seoMetadata {
        canonical
        keywords
        title
        type
        abstract {
          abstract
        }
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
    }
    template: contentfulWebPageTemplate(model: { eq: "Care Note" }) {
      slugPrefix
      title
      sections {
        __typename
        ... on ContentfulTemplateSection {
          __typename
          title
        }
      }
    }
  }
`
